.my-data-sources-page {
  padding: 20px;
  margin-top: 70px;
}

.add-data-source-button {
  background-color: #4285F4;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.add-data-source-button:hover {
  background-color: #0056b3;
}

.data-sources-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.data-source-header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: #fff;
  font-weight: bold;
  border-radius: 5px;
}

.data-source-item {
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
}

.data-source-info,
.data-source-type,
.data-source-date,
.data-source-actions {
  display: flex;
  align-items: center;
}

.data-source-info {
  width: 30%;
}

.data-source-type {
  width: 20%;
  justify-content: center;
}

.data-source-date {
  width: 20%;
  justify-content: center;
}

.data-source-actions {
  width: 30%;
  justify-content: flex-end;
}

.data-source-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  vertical-align: middle;
  margin-top: 13px;
}

.sortable {
  cursor: pointer;
  user-select: none;
}

.view-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #4285F4;
  padding: 0; /* Ensure no padding */
  font-size: 16px; /* Ensure uniform font size */
  margin-right: 45px; /* Add margin to separate from delete button */
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  color: red;
  padding: 0; /* Ensure no padding */
  font-size: 16px; /* Ensure uniform font size */
  margin-right: 15px;
}

.view-button:hover, .delete-button:hover {
  text-decoration: underline; /* Add underline on hover */
}

.hidden {
  visibility: hidden; /* Hide the element */
}

.header-source {
  width: 30%;
}

.header-type {
  width: 20%;
  display: flex;
  justify-content: center; /* Center align the "Type" header */
}

.header-date {
  width: 20%;
  display: flex;
  justify-content: center; /* Center align the "Date" header */
}

.header-actions {
  width: 30%;
}

.sortable span {
  display: flex;
  flex-direction: column; /* Stack arrows vertically */
  align-items: center;
  margin-left: 5px;
  color: #d3d3d3; /* Lighter grey color */
  font-size: 0.8em; /* Smaller font size */
}

.sortable.active span {
  color: black; /* Active arrow color */
}

.sortable:hover {
  cursor: pointer;
}


.confirm-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.confirm-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.my-data-sources-page .my-datasources-rename-icon {
  margin-left: 15px;
  cursor: pointer;
  width: 18px; /* Adjust size as needed */
  height: 18px; /* Adjust size as needed */
}

.rename-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-size: 16px;
  width: 300px; /* Adjusted width */
  max-width: 100%;
  text-align: center; /* Center align the content */
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.confirm-rename-button,
.cancel-button,
.confirm-delete-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.cancel-button {
  background-color: #ccc;
  font-size: 14px;
}

.confirm-delete-button {
  background-color: #d9534f;
  color: white;
  font-size: 14px;
}

.confirm-rename-button {
  background-color: #4285F4;
  color: white;
  font-size: 14px;
}

.refresh-icon {
  margin-left: 10px;
  cursor: pointer;
  width: 26px; /* Adjust size as needed */
  height: 26px; /* Adjust size as needed */
  vertical-align: center;
}

.refresh-icon.rotating {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.retailer-portals-header {
  margin-top: 60px;
}

.retailer-portals-body {
  margin-left: 10px;
  font-size: 12px;
}
