.data-sources-page {
  padding: 20px 20px 20px 20px;
}

.data-sources-page h2 {
  font-size: 24px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.data-sources-back-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #000;
}

.data-sources-back-arrow {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.error-message {
  color: red;
}

.success-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.success-popup h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.success-popup .success-icon {
  width: 70px;
  height: 70px;
  margin-bottom: 40px;
}

.success-popup .continue-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 14px;
}

.success-popup .data-sources-connect-button:hover {
  background-color: #0056b3;
}

.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.loading-gif {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.data-sources-connect-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 14px;
}


.form-label {
  display: flex;
  flex-direction: column;
  font-weight: normal; /* Makes the label text not bold */
  font-family: 'Poppins', sans-serif; /* Sets the font family to Poppins */
}

.form-input,
.form-textarea {
  margin-top: 5px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
  font-family: 'Poppins', sans-serif; /* Ensures the font is Poppins */
  width: 100%;
  box-sizing: border-box;
}

.form-textarea {
  height: 80px;
  resize: none;
}


.cancel-button:hover {
  background-color: #b3b3b3
}

.data-sources-connect-button:hover {
  background-color: #0056b3;
}

.snowflake-data-source-modal {
  max-width: 800px; /* Increase the modal width */
  width: 100%;
}

.snowflake-fields {
  display: flex;
  gap: 20px; /* Adds space between the two columns */
  margin-top: 20px;
}

.column {
  flex: 1; /* Each column takes equal space */
  display: flex;
  flex-direction: column;
}

.form-label {
  margin-bottom: 15px; /* Space between each label-input pair */
}

.form-input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}
