.sidebar {
    width: 250px;
    background-color: #f5f5f5;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
}

.new-chat-button {
    background-color: #fff; /* Button background color */
    color: #000; /* Text color */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 16px;
    cursor: pointer;
    width: 70%; /* Full width */
    margin-top: 60px;
    margin-bottom: 20px;
}

.new-chat-button .plus-icon {
    margin-right: 8px; /* Add some space between the icon and text */
    font-size: 18px;
}

.chats-list {
    margin-top: 20px;
    flex: 1;
    overflow-y: auto; /* Make the chats list scrollable */
}

.chat-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    position: relative; /* Added to position the dropdown relative to the chat item */
}

.chat-item.current-chat {
    background-color: #f0f0f0;
}

.chat-item span {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
}


.plus-icon {
    margin-left: 8px; /* Space between the icon and text */
    width: 16px; /* Adjust the icon width */
    height: 16px; /* Adjust the icon height */
}

.more-icon {
    display: none;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: none;
    vertical-align: middle;
}

.chat-item:hover .more-icon {
    display: inline-block;
}

.chat-item:hover {
    background-color: #f0f0f0;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
    gap: 20px; /* Add this line to create space between buttons */
  margin-top: 20px;
}

.cancel-delete-button,
.confirm-delete-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
    width: 90px;
}

.cancel-delete-button {
  background-color: #ccc;
  font-size: 14px;
}

.confirm-delete-button {
  background-color: #d9534f;
  color: white;
  font-size: 14px;
}

.confirm-delete-button:hover {
    background-color: #ff7875;
}

.cancel-delete-button:hover {
    background-color: #d9d9d9;
}

/* Sidebar and chat list styling */
.more-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

/* Styling for the dropdown */
.dropdown-menu {
    position: absolute;
    top: 40px; /* Adjust this to control the position relative to the icon */
    right: 0px; /* Align the dropdown with the right side of the icon */
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 1000; /* Make sure it's visible on top */
    padding: 5px;
    display: flex;
    flex-direction: column;
    min-width: 120px; /* Ensure a minimum width */
}

.dropdown-menu button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    text-align: left;
    font-size: 14px;
    color: black;
    width: 100%; /* Ensure the button takes up full width */
}

.dropdown-menu button:hover {
    background-color: #f0f0f0;
    border-radius: 5px;
}

.sidebar-close-button {
    background-color: #ccc;
    color: #333;
    font-size: 14px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.sidebar-close-button:hover {
    background-color: #d9d9d9;
}

.confirm-archive-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

}

.confirm-archive-modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    width: 100%;
    z-index: 1001;
}
