body {
    font-family: 'Poppins', sans-serif;
}

.forgot-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
}

.logo img {
    width: 300px;
    height: auto;
    margin-bottom: 10px;
}

.forgot-password-form {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 90%;
    text-align: center;
}

.success-message {
    font-size: 16px;
    margin-bottom: 30px;
    line-height: 1.5;
}

.signup-link {
    margin-top: 20px;
    font-size: 14px;
}

.signup-link a {
    text-decoration: none;
    color: #007bff;
}
