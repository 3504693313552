/* UserAccessPage.css */

/* Ensure the whole page has padding to create space */
.user-access-page {
    padding: 20px 20px 20px 20px; /* Top and bottom padding */
}

/* Adjust the search input margin */
.user-access-search {
    margin-bottom: 20px;
    margin-top: 30px;
}

.user-access-page h1 {
  font-size: 24px;
  margin-bottom: 50px;
  margin-left: 10px;
}

/* Style the table for consistency */
.user-access-table {
    width: 100%;
    margin-top: 20px;
    background-color: #fff; /* If you want a consistent background */
    border-radius: 8px; /* Optional: gives a rounded corner look */
    padding: 20px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
}

.data-sources-back-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #000;
}

.data-sources-back-arrow {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
