/* General page layout */
.schema-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px;
}

.data-sources-back-button-container {
  margin-top: 80px;
}

.data-sources-back-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #000;
}

.data-sources-back-arrow {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

/* Header styles */
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 80px;
  position: relative;
}

.datasource-title {
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
}

.dropdown-arrow {
  font-size: 8px; /* Smaller arrow */
  margin-left: 3px;
  color: black;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
}

.dropdown-menu div {
  padding: 8px;
  cursor: pointer;
}

.dropdown-menu div:hover {
  background: #f0f0f0;
}

/* View switcher styles */
.view-switcher {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.view-switcher button {
  background: none;
  border: 1px solid #ddd;
  padding: 10px;
  cursor: pointer;
  color: black;
  border-radius: 8px;
}

.view-switcher button.active {
  background-color: black;
  color: #fff;
  border-color: black;
}

/* Content and sidebar styles */
.schema-content {
  display: flex;
  flex-grow: 1;
}

.sidebar {
  width: 200px;
  padding: 20px;
  border-right: 1px solid #c7c7c7;
  background-color: #fff;
  height: 100%;
  border: 1px solid #ddd; /* Light grey border */
  border-radius: 5px;
  margin-top: 58px;
}

.tables-header, .saved-queries-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400; /* Lighter font weight */
}

.tables-header h3,
.saved-queries-header h3 {
  font-weight: normal; /* Set to normal font weight */
}

.tables-header .dropdown-arrow, .saved-queries-header .dropdown-arrow {
  font-size: 8px; /* Smaller arrow */
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  font-weight: normal; /* Normal font weight for sidebar list items */
  cursor: pointer; /* Change cursor to pointer */
  margin-left: 25px;
}

.sidebar li.active {
  font-weight: bold; /* Bold font weight for active sidebar list items */
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  margin-top: -145px;
}

.metadata, .data-preview {
  padding: 20px;
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: #fff; /* Changed to white */
}

.columns-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff; /* White background */
}

.columns-table th, .columns-table td {
  border: 1px solid #ddd; /* Thin grey outline */
  padding: 12px 8px;
  text-align: left;
}

.columns-table th {
  background-color: #fff; /* White background */
  font-weight: bold;
}

.columns-table tr {
  background-color: #fff; /* White background */
}

.columns-table tr:hover {
  background-color: #f9f9f9; /* Light grey hover effect */
}

.columns-table tr.expanded {
  background-color: #fff;
}

.column-description {
  display: none;
  padding: 8px;
}

.column-description.expanded {
  display: table-row;
}

.column-description td {
  padding-left: 20px;
  color: #555;
}

.table-description {
  margin-left: 20px;
  width: 200px;
  height: 25px;
  font-size: 14px;
  resize: none;
}

.arrow {
  font-size: 8px; /* Smaller arrow */
  margin-left: 3px;
  color: #888888;
  visibility: hidden; /* Hide arrow by default */
}

.expanded .arrow {
  visibility: visible; /* Show arrow when row is expanded */
}

.description-label {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  font-weight: normal;
  line-height: 1.5;
  color: black;
  white-space: nowrap; /* Ensure the label does not wrap */
}

.description-input,
.table-description {
  display: inline-block;
  width: 500px; /* Adjust based on the width of the label */
  height: 60px; /* Set a fixed height */
  vertical-align: middle;
  resize: none;
  padding: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  overflow-y: auto; /* Make it scrollable */
  margin-left: 10px;
}

.table-description-container,
.column-description-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.expandable-row {
  padding: 10px 0; /* Add more padding to make the row taller */
  cursor: pointer;
}

.description-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.column-type {
  display: inline-block;
  background-color: #d6e0f5; /* Light purple background */
  border-radius: 12px; /* Rounded corners */
  padding: 2px 8px; /* Padding inside the badge */
  font-size: 12px; /* Font size for the badge text */
  color: #000; /* Black text color */
  margin-left: 10px; /* Space between column name and type */
}

.metadata-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.data-preview-container {
  position: relative;
  overflow-x: auto;
  max-width: 100%;
}

.data-preview-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 145px;
  background-color: #fff;
}

.data-preview-table th, .data-preview-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.data-preview-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.data-preview-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.data-preview-message {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #888;
  text-align: right;
  position: absolute;
  right: 0;
  margin-top: 230px;
  margin-right: 30px;
}

.preview-loading-gif {
  height: 70px;
  width: 70px;
  margin-top: 150px;
  margin-left: 450px;
}