.data-source-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 80px;
}

.data-source-card {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  width: calc(25% - 40px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.data-source-card h3 {
  font-size: 18px;
  margin-bottom: 30px;
  font-weight: normal;
}

.data-source-icon {
  width: 70px;
  height: 70px;
  margin-bottom: 16px;
}

.add-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.add-button:hover {
  background-color: #0056b3;
}
