.data-source-modal {
  width: 80%;
  max-width: 500px;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.data-source-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-form-fields {
  margin-bottom: 20px;
}

.modal-form-fields label {
  display: block;
  margin-bottom: 30px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
}

.cancel-button, .connect-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.cancel-button {
  background: #ccc;
  margin-right: 10px;
}

.connect-button {
  background: #007bff;
  color: white;
}
