body {
    font-family: 'Poppins', sans-serif;
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f5f5f5;
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
}

.logo img {
    width: 300px;
    height: auto;
    margin-bottom: 10px;
}

.login-form {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 90%;
    text-align: center;
}

.login-form h2 {
    margin-bottom: 20px;
}

.input-group {
    margin-bottom: 20px;
    text-align: left;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
}

.input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
}

.actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.actions a {
    text-decoration: none;
    color: #007bff;
    font-size: 14px;
}

.login-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
}

.login-button:hover {
    background-color: #0056b3;
}