.dashboards-page {
    padding: 50px 20px;
    padding-left: 30px;
}

.dashboards-page h1 {
    font-size: 24px;
    margin-top: 60px;
}

.dashboards-page h2 {
    font-size: 24px;
}


.elements-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
    margin-top: 30px;
    background-color: white;
}

.dashboard-item,
.element-item {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px;
    width: 25%;
    height: 300px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    margin: 10px;
}

.dashboard-item h3,
.element-item h3 {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: normal;
    text-align: left;
}

.element-thumbnail {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
}

.dashboard-thumbnail {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    padding-top: 40px; /* Adjust padding as needed */
}


.dashboard-thumbnail canvas,
.element-thumbnail canvas {
    width: 100%;
    height: auto;
}

.dashboard-item:hover,
.element-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.element-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    z-index: 10;
    margin-left: 10px;
}

.dashboard-name,
.element-name {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
}

.dashboard-rename-icon,
.elements-rename-icon {
    width: 18px;
    height: 18px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
    padding-top: 2px;
}

.dashboard-duplicate-icon,
.elements-duplicate-icon {
    width: 18px;
    height: 18px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
    padding-top: 3px;
    padding-right: 4px;
}

.element-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dashboard-icons,
.element-icons {
    position: relative;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 5px;
    padding-bottom: 30px;
}

.elements-delete-icon,
.dashboard-delete-icon {
    width: 23px;
    height: 23px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.element-item:hover .icon {
    opacity: 1;
}

.dashboard-item:hover .icon {
    opacity: 1;
}

.element-thumbnail {
    padding-top: 40px;
}

.dashboard-create-new-button {
  background-color: #4285F4;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dashboard-create-new-button:hover {
    background-color: #0056b3;
}

.dashboard-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
    background-color: white;
    margin-top: 40px;
    margin-bottom: 50px;
}

.container-dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    z-index: 10;
}


/* Modal Overlay */
.dashboard-create-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Darken the overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Content */
.dashboard-create-modal-content {
    background: white;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Stronger shadow for emphasis */
    max-width: 400px;
    width: 90%; /* Ensure modal is responsive */
    text-align: center;
}

/* Modal Title */
.dashboard-create-modal-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
}

/* Input Field */
.dashboard-create-modal-input {
    width: 100%;
    padding: 12px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box; /* Ensures padding is included in width */
}

/* Modal Buttons */
.dashboard-create-modal-buttons {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
}

/* Cancel Button */
.dashboard-create-cancel-button {
    background-color: #ccc;
    color: #333;
    font-size: 14px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.dashboard-create-cancel-button:hover {
    background-color: #b3b3b3; /* Darken on hover */
}

/* Confirm/Create Button */
.dashboard-create-confirm-button {
    background-color: #4285F4;
    color: white;
    font-size: 14px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.dashboard-create-confirm-button:hover {
    background-color: #0056b3; /* Darken on hover */
}

.confirm-modal {
    text-align: center;
}