.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    height: 60px; /* Set a fixed height for the navbar */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; /* Ensure the navbar stays on top */
}

.navbar-logo {
    display: flex;
    height: 40px;
    align-items: center;
    text-decoration: none;
    cursor: pointer;  /* Make cursor a pointer */
    user-select: none; /* Disable text selection */
}

.logo {
    height: 40px; /* Adjust the height as needed */
    margin-top: 80px;
}

.navbar-links a {
    margin: 0 10px;
    text-decoration: none;
    color: #333;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    user-select: none; /* Disable text selection */
}

.navbar-links a:hover {
    background-color: #e0e0e0;
}

.active-link {
    background-color: #f0f0f0; /* Light background color */
}
