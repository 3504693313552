.archived-chats-page {
  padding: 20px 20px 20px 20px;
}


.archived-chats-page h1 {
  font-size: 24px;
  margin-bottom: 10px;
  margin-left: 10px;
}


.archived-chats-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 14px;
}

.archived-chat-title {
    font-weight: normal;
    margin-bottom: 10px;
}

.archived-chat-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    width: 500px;
}

.chat-info {
    display: flex;
    flex-direction: column;
}

.chat-title {
    font-size: 16px;
    font-weight: bold;
}

.chat-created {
    font-size: 14px;
    color: #888;
}

/* Update for text-only buttons */
.unarchive-button {
    background-color: transparent;
    border: none;
    color: #000;
    padding: 0;
    font-weight: normal;
    cursor: pointer;
    font-size: 16px;;
}

.unarchive-button:hover {
    background-color: transparent;
    color: #888;
}

.unarchive-button{
    margin-right: 20px;
}

.archive-delete-button {
    background-color: transparent;
    border: none;
    color: red;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
}

.archive-delete-button:hover {
    color: #ff7875;
    text-decoration: none;
}

/* Delete Confirmation Popup */
.archive-delete-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.archive-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
}

.archive-popup-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.archive-confirm-delete-button {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.archive-cancel-delete-button {
    background-color: #b3b3b3;;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.archive-confirm-delete-button:hover {
    background-color: #ff7875;
}

.archive-cancel-delete-button:hover {
    background-color: #d9d9d9;
}

.data-sources-back-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #000;
}

.data-sources-back-arrow {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}