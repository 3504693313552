.home {
    display: flex;
    height: 100vh; /* Adjust height to account for navbar */
    overflow: hidden;
}

.sidebar {
    width: 250px;
    overflow: auto;

}

.chat-interface {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 20px;
    padding-top: 100px; /* Add top padding to account for the navbar */

}
