.edit-dashboard {
    padding: 20px;
    position: relative;
}

.edit-dashboard-back-button-container {
    margin-bottom: 20px;
}


.edit-dashboard-back-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #000;
    margin-top: 80px;
    margin-bottom: 30px;
}

.edit-dashboard-back-arrow {
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

.edit-dashboard-container {
    position: relative;
    width: 100%;
    background-color: #fff;
    border-radius: 7px;
    transition: height 0.2s ease; /* Optional: Smooth transition when height changes */
    min-height: 1000px; /* Optional: Set a minimum height to prevent it from shrinking too much */
    height: 100%;
    padding-bottom: 50px;
    overflow-y: auto; /* Enables scrolling within the container */
}

.available-elements {
    margin-top: 20px;
}

.edit-elements-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.edit-element-item {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
    background-color: #fff;
    cursor: move;
}

.save-dashboard-button {
    margin-top: 150px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.save-dashboard-button:hover {
    background-color: #0056b3;
}


.edit-elements-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.available-element {
    width: 90%;
    height: auto;
    cursor: pointer;
    border: 1px solid #ddd;
    padding: 10px;
    background-color: white;
    border-radius: 5px;
}

.empty-message {
    font-size: 24px;
    color: #ccc;
    padding: 40px;
}

.available-elements-sidebar {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 300px;
    background-color: #fff;
    padding: 15px;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-radius: 7px;
    overflow-y: auto;
    transition: transform 0.3s ease;
    z-index: 100;
    margin-top: 230px;
}

.available-elements-sidebar.closed {
    transform: translateX(85%);
}


.toggle-sidebar-icon {
    width: 14px;
    height: 14px;
    cursor: pointer;
    display: block;
    margin-bottom: 5px;
}


.available-elements-sidebar h2 {
    margin-top: 0;
    font-size: 18px; /* Change this value to the desired font size */
    margin-bottom: 30px;
}

.search-input {
    display: inline-block;
    width: 150px; /* Adjust width to fit beside the title */
    padding: 8px;
    margin-left: 30px; /* Space between the title and search bar */
    border: 1px solid #ccc;
    border-radius: 4px;
    vertical-align: middle; /* Aligns the input with the h2 */
}

.available-elements-sidebar h2 {
    display: inline-block;
    margin-top: 20px; /* Adjust the margin as needed */
    font-size: 16px; /* Adjust the font size */
}

.available-element canvas {
    max-width: 90%; /* Adjusts the width of the graph */
    max-height: 150px; /* Limits the height of the graph */
    height: auto;
    width: auto;
    margin: 0 auto; /* Centers the graph */
}

.element-name {
    margin-top: 10px; /* Add more top padding */
    font-size: 14px;
    color: #333;
}

.unsaved-changes-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    margin: auto;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.unsaved-changes-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.modal-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}

.save-changes-button, .exit-without-saving-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.save-changes-button {
    background-color: #007bff;
    color: white;
}

.exit-without-saving-button {
    background-color: #dc3545;
    color: white;
}

.unsaved-changes-modal h2 {
    font-size: 18px; /* Smaller font size */
    margin-bottom: 20px; /* Add some space below the title */
}

.edit-element-form {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between all form fields */
    padding: 10px;
}

.resize-handle-icon {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 24px;
    height: 24px;
    cursor: ns-resize;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.resize-handle-icon {
    width: 16px;
    height: 16px;
    opacity: 0.6;
}

.edit-dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-right: 10px;
}

.back-and-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.dashboard-name-and-status {
    display: flex;
    align-items: center;
    margin-top: 10px; /* Adjust the margin to position the name correctly */
}

.edit-dashboard-back-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #000;
}

.edit-dashboard-back-arrow {
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

.dashboard-name {
    font-size: 24px;
    margin-right: 10px;
    font-weight: normal;
    margin-left: 10px;
}

.unsaved-changes-indicator-dashboard {
    color: #808080; /* Light gray color */
    font-size: 16px;
    margin-left: 10px;
}

.saved-changes-indicator-dashboard {
    color: #808080; /* Light gray color */
    font-size: 16px;
    margin-left: 10px;
}

.add-element-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 15px;

}

.add-element-button:hover {
    background-color: #0056b3;
}

.available-elements-sidebar label {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Adds space between label/input pairs */
}

.available-elements-sidebar label > input,
.available-elements-sidebar label > select {
    margin-left: 10px; /* Adds space between label and input */
}


.available-elements-sidebar input[type="number"] {
    width: 50px; /* Set the desired width */
}

.available-elements-sidebar input[type="text"],
.available-elements-sidebar input[type="number"] {
    height: 25px; /* Adjust the height to match your input fields */
    padding: 5px; /* Adjust padding as necessary */
    border-radius: 4px; /* Keep the border radius consistent */
    border: 1px solid #ccc; /* Consistent border style */
    font-size: 14px; /* Ensure font size is consistent */
}

.available-elements-sidebar select {
    height: 35px; /* Adjust the height to match your input fields */
    padding: 5px; /* Adjust padding as necessary */
    border-radius: 4px; /* Keep the border radius consistent */
    border: 1px solid #ccc; /* Consistent border style */
    font-size: 14px; /* Ensure font size is consistent */
}

/* Dropdown Button Styles */
.refresh-dropdown-container {
    position: relative;
    display: inline-block;
}

.refresh-dropdown-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 15px;
    font-size: 14px;
}

.refresh-dropdown-button:hover {
    background-color: #0056b3;
}

.refresh-dropdown-menu {
    position: absolute;
    top: 100%;  /* Positioned below the button */
    right: 10px;  /* Align to the left of the button */
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 1000;
    list-style: none;
    padding: 5px;
    width: 150px; /* Ensure it matches the button width */
    font-size: 14px;
}

.refresh-dropdown-menu li {
    padding: 10px 15px;
    cursor: pointer;
}

.refresh-dropdown-menu li:hover {
    background-color: #f0f0f0;
    border-radius: 5px;
}

/* Styling for the calendar modal */
.schedule-refresh-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;  /* Increased width for more horizontal space */
    max-height: 500px;  /* Limit height to make it shorter */
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;  /* Ensure it's above other elements */
    text-align: center;
    overflow-y: auto;  /* Allow scrolling if content exceeds modal height */
}

.schedule-refresh-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;  /* Ensure it covers the background */
}

.refresh-modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.date-picker-wrapper {
    display: inline-block;
    width: 100%;  /* Ensure it spans the width of the modal */
    max-width: 400px;  /* Increased width to match modal */
    margin: 0 auto;  /* Center the DatePicker inside the modal */
}

.react-datepicker {
    width: 100%;  /* Prevent DatePicker from overflowing */
    max-width: 400px;  /* Constrain width within modal */
}

.react-datepicker__month-container {
    max-height: 200px;  /* Further limit the height of the calendar */
    overflow-y: auto;  /* Add scroll behavior for overflow */
}

.react-datepicker__time-list {
    max-height: 100px;  /* Limit the height of the time list */
    overflow-y: auto;  /* Enable scrolling */
}

.react-datepicker__header {
    background-color: white;
    border-bottom: 1px solid #ddd;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range {
    background-color: #0069d9;  /* Add a primary color for selected days */
    color: white;
}

.react-datepicker__time-container {
    width: 100%;  /* Ensure time picker width fits */
    max-width: 400px;
}

.day-selection, .time-input, .repeat-selection {
    margin-bottom: 15px;  /* Adjust the spacing between sections */
}

input[type="checkbox"] {
    margin-right: 8px;  /* Spacing between checkboxes and their labels */
}


.set-schedule-button {
    background-color: #007bff; /* Blue color for the action button */
    color: white;
    font-size: 14px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

.set-schedule-button:hover {
    background-color: #0056b3; /* Darken the blue color on hover */
}


.refresh-schedule-cancel-button {
    background-color: #ccc; /* Blue color for the action button */
    color: #333;
    font-size: 14px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

.refresh-schedule-cancel-button:hover {
    background-color: #b3b3b3; /* Darken on hover */
}

.text-format-button {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    margin-bottom: 8px;
    font-size: 16px;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    text-align: left;
    width: 100%;
    color: #333;
}

.text-format-button:hover {
    background-color: #f0f0f0;
}

.text-format-button .format-title {
    font-weight: bold;
    font-size: 16px;
    margin-right: 12px;
}

.text-format-button .format-title sub {
    font-size: 12px;
    vertical-align: sub;
}

.add-text-options {
    margin-bottom: 20px;
}


.generate-commentary-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 30px;
    margin-left: 5px;
}

.generate-commentary-button:hover {
    background-color: #0056b3;
}


.delete-element-button {
    padding: 10px 20px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-top: 10px;
}

.delete-element-button:hover {
    background-color: #c33442;
}


.color-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.color-input {
  margin-left: 10px;
}

.color-picker-dropdown {
  margin-top: 10px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.color-dropdown-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.custom-arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
}



.share-dropdown-button {
    background-color: #007bff; /* Blue color for the action button */
    color: white;
    font-size: 14px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 15px;
}

.share-dropdown-button:hover {
    background-color: #0056b3; /* Darken the blue color on hover */
}

/* Dropdown Button Styles */
.share-dropdown-container {
    position: relative;
    display: inline-block;
}

.share-dropdown-menu {
    position: absolute;
    top: 100%;  /* Positioned below the button */
    right: 10px;  /* Align to the left of the button */
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 1000;
    list-style: none;
    padding: 5px;
    width: 150px; /* Ensure it matches the button width */
    font-size: 14px;
}

.share-dropdown-menu li {
    padding: 10px 15px;
    cursor: pointer;
}

.share-dropdown-menu li:hover {
    background-color: #f0f0f0;
    border-radius: 5px;
}