.chat-window {
    flex: 1; /* This ensures it takes all available space */
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px); /* Adjust this based on your input section height */
    overflow-y: auto; /* This should allow scrolling */
    padding: 10px;
}

.chat-message {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    max-width: 60%;
    white-space: pre-wrap; /* Ensure that white spaces and new lines are preserved */
}

.user-message {
    margin-left: auto;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 10px;
    align-self: flex-end;
}

.gpt-message {
    margin-right: auto;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
}

.chart-message .message-text {
    margin-bottom: 10px;
}

.chart-message .message-chart {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 350px;
}

.chat-input-section {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #ccc;
    border-top: none;
}

.chat-input-section input {
    flex: 1;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
}

.chat-input-section button {
    margin-left: 10px;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid #fff;
    background-color: #000000;
    color: #fff;
    cursor: pointer;
}

.chat-input-section button:hover {
    background-color: #9c9c9c;
}

.chat-message.loading {
    text-align: center;
    padding: 10px;
}

.chat-message.loading img {
    height: 50px;
    width: 50px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.rename-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px; /* Adjusted width */
    max-width: 100%;
    text-align: center; /* Center align the content */
}

.modal-content {
    width: 600px;   /* Set a fixed width for the modal */
    max-width: 100%;  /* Ensure it remains responsive */
    word-wrap: break-word;  /* Break long words */
    white-space: pre-wrap;  /* Preserve white spaces and wrap long text */
    position: relative;  /* Position for the close button */
}

.sql-query {
    word-wrap: break-word;  /* Break long words */
    white-space: pre-wrap;  /* Preserve white spaces and wrap long text */
}


.modal-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.cancel-button, .confirm-rename-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button {
    background-color: #ccc;
    font-size: 14px;
}

.confirm-rename-button {
    background-color: #4285F4;
    color: white;
    font-size: 14px;
}

.save-element-button {
    background-color: white;
    color: black;
    border: 1px solid #ccc;
    padding: 5px 10px; /* Adjust padding as needed */
    margin-top: 20px; /* Adds space between the button and the chart */
    cursor: pointer;
    border-radius: 7px;
    display: inline-block; /* Ensures no unnecessary width */
    white-space: nowrap; /* Prevents text wrapping */
    width: 150px;
}

.save-element-button:hover {
    background-color: #e0e0e0; /* Changes background color on hover */
}

.view-data-button {
    background-color: white;
    color: black;
    border: 1px solid #ccc;
    padding: 5px 10px; /* Adjust padding as needed */
    margin-top: 20px; /* Adds space between the button and the chart */
    cursor: pointer;
    border-radius: 7px;
    display: inline-block; /* Ensures no unnecessary width */
    white-space: nowrap; /* Prevents text wrapping */
    width: 150px;
    margin-left: 15px;
}

.view-data-button:hover {
    background-color: #e0e0e0; /* Changes background color on hover */
}

.chart-type-button {
    background-color: white;
    color: black;
    border: 1px solid #ccc;
    padding: 5px 10px; /* Adjust padding as needed */
    margin-top: 20px; /* Adds space between the button and the chart */
    cursor: pointer;
    border-radius: 7px;
    display: inline-block; /* Ensures no unnecessary width */
    white-space: nowrap; /* Prevents text wrapping */
    width: 150px;
    margin-left: 15px;
}

.chart-type-button:hover {
    background-color: #e0e0e0; /* Changes background color on hover */
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Darken the overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content-scrollable {
    max-height: 500px; /* Fixed height */
    max-width: 800px; /* Fixed width */
    overflow-y: auto;
    overflow-x: auto;
    padding: 20px;
    background-color: white; /* Ensure white background */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.close-button-top-right {
    background-color: #4285F4;
    color: white;
    font-size: 14px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 80px;
    height: 40px;
    position: absolute;
    top: 15px;    /* Adjust the top position */
    right: 25px;  /* Adjust the right position */
}




.close-button-top-right:hover {
  background-color: #0056b3;
}

.left-justify {
    text-align: left;
}

.table-bordered {
    border-collapse: collapse;
    width: 100%;
}

.table-container {
    max-width: 100%;    /* Limit the table container to the modal's width */
    overflow-x: auto;   /* Enable horizontal scrolling when necessary */
}

.table-bordered th, .table-bordered td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left; /* Ensure all table content is left-justified */
    font-weight: normal;
}

.chart-options-container {
    display: flex;
    gap: 10px;
    /* Removed position: relative; since we are isolating the dropdown */
}

/* New Container for Chart Type Button and Dropdown */
.chart-type-container {
    position: relative; /* Position relative to align the dropdown properly */
    display: inline-block;
}

/* Adjusted Dropdown Menu Positioning */
.chart-type-container .dropdown-menu {
    position: absolute;
    top: -300%; /* Positions the dropdown directly below the button */
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 1000;
    padding: 10px; /* Adjusted padding for better spacing */
    display: flex;
    flex-direction: column;
    width: 200px; /* Set a max width to prevent it from being too wide */
    list-style-type: none; /* Remove bullet points */
    margin: 0; /* Ensure no extra margin */
    font-size: 14px;
    /* Removed padding: 0; since padding is already set above */
}

.dropdown-menu li {
    padding: 8px 12px;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background-color: #f0f0f0;
    border-radius: 5px;
}

/* Remove lines between items */
.dropdown-menu li + li {
    border-top: none;
}

.loading-gif {
    height: 50px;
    width: 50px;
    margin-top: 100px;
}
