.settings-page {
    padding: 100px 30px;  /* Moves the content to the right and down */
}

.settings-page h1 {
    font-size: 24px;  /* Adjust the size to your preference */
    font-weight: bold;  /* You can keep the boldness or reduce it */
    margin-bottom: 40px;
}


.settings-section {
    margin-bottom: 40px;
}

.settings-section h2 {
    font-size: 20px;
    margin-bottom: 10px;
}

.settings-link {
    color: #000;
    text-decoration: none;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
}

.arrow-right-icon {
    width: 18px;
    height: 18px;
    margin-left: 5px;
    transform: rotate(180deg);
    display: inline-block;
    vertical-align: middle;
}

.settings-link:hover {
    text-decoration: underline;
}

.settings-link {
    font-weight: normal;
}