@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

input, button, a, label, h1, h2, h3, h4, h5, h6, p {
    font-family: 'Poppins', sans-serif;
}
